import React, { useState } from 'react';

import introSmall from '../assets/video/videoIntro-480.mp4';
import introMed from '../assets/video/videoIntro-720.mp4';
import introLarge from '../assets/video/videoIntro-1080.mp4';
import introThumb from '../assets/video/videoIntroThumb.png';
import { useWindowSize } from '../hooks/useWindowSize';
import './video.css';

const introVideo = {
  sm: introSmall,
  md: introMed,
  lg: introLarge,
  thumb: introThumb,
};
const Video = ({ video: vid = introVideo }) => {
  const { width } = useWindowSize();
  const getVideoSrc = (width) => {
    if (width >= 992) return vid.lg || vid.md || vid.sm;
    if (width >= 600) return vid.md || vid.sm || vid.lg;
    return vid.sm || vid.md || vid.lg;
  };

  // eslint-disable-next-line no-unused-vars
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const src = getVideoSrc(width);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  return (
    <div className='container'>
      {/*<img
        src={vid.thumb}
        className='video-thumb tiny'
        alt='thumb'
        style={{ opacity: isVideoLoaded ? 0 : 1 }}
				(above for image, below for video)
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      />*/}
      <video
        controls
        playsInline
        muted
        src={src}
        poster={vid.thumb}
        onLoadedData={onLoadedData}
      />
    </div>
  );
};

export default Video;
