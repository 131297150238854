import cx from 'classnames';
import {Link, graphql} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';

import {ReactComponent as Casket} from '../assets/icons/icon-casket.svg';
import {ReactComponent as Flowers} from '../assets/icons/icon-grave-flowers.svg';
//assets
import {ReactComponent as Handshake} from '../assets/icons/icon-handshake.svg';
import {ReactComponent as Urn} from '../assets/icons/icon-urn.svg';
import banner from '../assets/images/leafBannerSmaller.jpg';
import solidifiedRemainsSm from "../assets/video/solidified-remains-spanish-480.mp4";
import solidifiedRemainsMd from "../assets/video/solidified-remains-spanish-720.mp4";
import solidifiedRemainsLg from "../assets/video/solidified-remains-spanish-1080.mp4";
import solidifiedRemainsThumb from "../assets/video/solidified-remains-thumb.png";
//components
import Layout from '../components/layout';
import SEO from '../components/seo';
import StyledButton from '../components/styledButton';
import Video from "../components/video";

const video = {
    sm: solidifiedRemainsSm,
    md: solidifiedRemainsMd,
    lg: solidifiedRemainsLg,
    thumb: solidifiedRemainsThumb,
};

const Espanol = () => (
    <Layout
        headerClass='relative bg-white'
        hasHero={true}
        heroImage={banner}
        heroHead='Ciudad de Robles'
        heroSubHead='Funeraria y Cremaciónes'
        heroText='Disponibles las 24 horas del día, 7 días a la semana.'
    >
        <SEO
            title='Ciudad de Robles Funeraria y Cremaciónes'
            description='City of Oaks Funeral Home provides service in Spanish unsurpassed service for direct cremation, traditional funerals, or immediate burial. In Raleigh, serving all of North Carolina.'
        />
        <div className='flex flex-col mt-10 md:mt-16'>
            <div className='w-16 h-16 mb-4 self-center'>
                <Handshake/>
            </div>

            <div className='mb-2 text-3xl text-green-700 font-serif font-hairline'>
                <h2>Nuestra visión </h2>
            </div>
            <p>
                Nuestra visión es poder ofrecer el mejor servicio cambiando lo
                tradicional y lo práctico por lo contemporáneo sin dejar de darle honra
                a su ser amado, permitiendo tener el mejor servicio por el mejor costo.
                Ayudamos a la buena planificación para poder tomar las mejores
                decisiones durante el proceso funeral. Nos da mucha satisfacción poder
                asistir le en su idioma que se sienta más cómodo.
            </p>
            <p>
                Nuestra casa funeraria está localizada en 4900 Green Rd Raleigh, NC.
                Además ofrecemos nuestros servicios en todo el estado al mismo costó.
            </p>
            <h3 className='text-lg pt-3'>Ofrecemos los siguientes servicios:</h3>
            <ul className='list-disc'>
                <li>
                    Reparticiones de cuerpos
                    <br/>
                    (a Mexico (apoyados por el consulado) o a cualquier país de Centro o
                    Sur América)
                </li>
                <li>Entierros directos</li>
                <li>Cremaciones inmediatas</li>
            </ul>
        </div>
        <div className='flex flex-col mt-10 md:mt-16'>
            <h1 className='py-4'>
                <b>
                    Los servicios aquí mencionados no son los ùnicos. Si gusta, puede
                    añadir más detalles a sus servicios.
                </b>
            </h1>
            {/* services block here */}
            <p className='mb-2 text-4xl text-gray-800 self-center'>Servicios</p>
            <div className='flex flex-wrap justify-center items-stretch -mx-2'>
                <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
                    <div className='h-full m-2 p-4 flex flex-col items-center text-center'>
                        <div className='w-16 h-16 mb-4'>
                            <Urn/>
                        </div>
                        <h3 className='text-2xl w-full text-green-700 hover:text-green-600 hover:underline'>
                            Cremación de Cuerpos
                        </h3>
                        <p>$1196.75</p>
                        <div className='text-left mt-2 pl-4'>
                            {' '}
                            <p className='text-lg pt-3'>Incluye en el costo: </p>
                            <ul className='list-disc'>
                                <li>Transferencias de los restos a la Funeraria</li>
                                <li>Costó de cremación</li>
                                <li>
                                    Tres (3) certificados autenticados de defunción de Carolina
                                    del Norte
                                </li>
                                <li>Una urna rectangular para las cenizas</li>
                                <li>Impuestos de NC</li>
                            </ul>
                        </div>
                    </div>
                    <div className='self-center mx-auto items-center'></div>
                </div>
                {/* // services section divider */}
                <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
                    <div className='h-full m-2 p-4 flex flex-col items-center text-center'>
                        <div className='w-16 h-16 mb-4'>
                            <Casket/>
                        </div>
                        <h3 className='text-2xl w-full text-green-700 hover:text-green-600 hover:underline'>
                            Repatriaciones de cuerpos a Centro América
                        </h3>
                        <p>$4515.71</p>
                        <div className='text-left mt-2 pl-4'>
                            {' '}
                            <p className='text-lg pt-3'>Incluye en el costo: </p>
                            <ul className='list-disc'>
                                <li>Costos del director general y su personal</li>
                                <li>Remover el cuerpo del lugar de donde ocurrió la Muerte</li>
                                <li>Embalsamamiento del fallecido</li>
                                <li>Poner persona en el ataúd con su vestuario</li>
                                <li>Transportación al Aeropuerto de Raleigh</li>
                                <li>
                                    Ataúd con juntas de 20 Calibres de nuestra lista de precios de
                                    ataúdes
                                </li>
                                <li>
                                    Bandeja de aire de envio según lo requieran las aerolineas
                                </li>
                                <li>
                                    Dos horas de servicio en la capilla de nuestra funeraria
                                </li>
                                <li>Documentación completa de envío a otro País</li>
                                <li>
                                    Tres (3) certificados autenticados de defunción de Carolina
                                    del Norte
                                </li>
                                <li>Impuestos de NC</li>
                            </ul>
                        </div>
                    </div>
                    <div className='self-center mx-auto items-center'></div>
                </div>
                {/* // another service section divider */}
                <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
                    <div className='h-full m-2 p-4 flex flex-col items-center text-center'>
                        <div className='w-16 h-16 mb-4'>
                            <Flowers/>
                        </div>
                        <h3 className='text-2xl w-full text-green-700 hover:text-green-600 hover:underline'>
                            Entierros Directos
                        </h3>
                        <p>$2497.50</p>
                        <div className='text-left mt-2 pl-4'>
                            {' '}
                            <p className='text-lg pt-3'>Incluye en el costo: </p>
                            <ul className='list-disc'>
                                <li>Costos del director general y su personal</li>
                                <li>Remover el cuerpo cuando ocurre la muerte</li>
                                <li>Poner persona en el ataúd con su vestuario</li>
                                <li>Transportación al cementerio local</li>
                                <li>
                                    Ataúd de 20 Calibres sin juntas de nuestra lista de precios de
                                    ataúdes
                                </li>
                                <li>
                                    Tres (3) certificados autenticados de defunción Carolina del
                                    Norte
                                </li>
                                <li>Impuestos de NC</li>
                            </ul>
                        </div>
                    </div>
                    <div className='self-center mx-auto items-center'></div>
                </div>
            </div>
            {/* // end of services section */}
            <div className='flex flex-col mt-10 md:mt-16'>

                <div className='mb-2 text-3xl text-green-700 font-serif font-hairline'>
                    <h2>Restos Solidificados</h2>
                </div>
                <p>City of Oaks Cremation ofrece la hermosa opción de solificad las cenizas de tu fallecido que pueden
                    ayudarle a mantener los recuerdos de su ser querido cerca de su corazón. El proceso de
                    solidificación es una forma única y hermosa de crear una colección de entre 40 y 60 "piedras" que
                    podrá sostener, compartir, esparcir e incluso llevar de viaje, lo que le permitirá tener cerca a su
                    ser querido por donde usted vaya.</p>

                <p>Nuestro coste incluye el envío certificado de ida y vuelta a nuestro proveedor de solidificación, por
                    lo que puede estar seguro de que el proceso se llevará a cabo con el máximo cuidado.</p>

                <p>Si está interesado, le animamos a que vea el vídeo para obtener más información sobre esta opción. No
                    dude en ponerse en contacto con su director de funeraria si tiene alguna pregunta o si está listo
                    para empezar. Estamos aquí para ayudarle en todo lo que podamos.
                </p>
                <Video video={video}/>
            </div>
            <div className='flex flex-col mt-10 md:mt-16'>
                <p className='mb-2 text-3xl text-green-700 font-serif font-hairline self-center'>
                    Disponibles las 24 horas del día, 7 días a la semana.
                </p>
                <div
                    className='w-full sm:w-3/4 lg:w-1/2 my-4 px-6 py-4 self-center'
                    style={{
                        boxShadow:
                            '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
                    }}
                >
                    <h4 className='text-2xl text-center'>Contacto:</h4>
                    <p className='text-xl text-center'>
                        Ciudad de Robles Funeraria y Cremaciónes
                    </p>
                    <p className='text-lg text-center'>
                        <a href='tel:9194381649'>(919)438-1649</a>
                    </p>
                    <p className='text-lg text-center'>
                        <a href='mailto:info@cityofoakscremation.com'>
                            info@cityofoakscremation.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);
export default Espanol;
